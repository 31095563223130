// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`
// Copy correct file to environment.ts based on what version you need

export const environment = {
  production: false,
  backendUrl: "https://dev.v10.api.printeeapp.com/v1",
  backendWebsocket: "wss://dev.v10.api.printeeapp.com/websocket",
  frontendUrl: "https://www.printeepro.com",
};
